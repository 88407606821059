import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["checkbox", "controls"]

    toggleControls() {
        const checkboxes = this.checkboxTargets
        const areAnyCheckboxesChecked = checkboxes.some((checkbox) => checkbox.checked);
        this.controlsTarget.style.display = areAnyCheckboxesChecked ? 'flex' : 'none';
    }
    connect() {
        this.toggleControls();
    }
}