// app/javascript/controllers/discount_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["percentage", "amount", "afterDiscount"];

  connect() {
    this.originalPrice = parseFloat(this.element.dataset.discountOriginalPrice);
  }

  percentageChanged() {
    const percentage = parseFloat(this.percentageTarget.value) || 0;
    const discountAmount = (this.originalPrice * (percentage / 100)).toFixed(2);
    this.amountTarget.value = discountAmount;
    this.updateAfterDiscount(discountAmount);
  }

  amountChanged() {
    const discountAmount = parseFloat(this.amountTarget.value) || 0;
    const percentage = ((discountAmount / this.originalPrice) * 100).toFixed(2);
    this.percentageTarget.value = percentage;
    this.updateAfterDiscount(discountAmount);
  }

  updateAfterDiscount(discountAmount) {
    const afterDiscount = (this.originalPrice - discountAmount).toFixed(2);
    this.afterDiscountTarget.textContent = afterDiscount + ' KES';
  }
}