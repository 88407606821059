import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["county", "region", "location"];

  connect() {
    // Trigger fetching on page load if there's a selected county/region
    if (this.countyTarget.value) {
      this.fetchRegions(true);
    }
    
    // Event listeners for changes in dropdowns
    this.countyTarget.addEventListener("change", () => {
      this.fetchRegions();
    });

    this.regionTarget.addEventListener("change", () => {
      this.fetchLocations();
    });
  }

  async fetchRegions(isInitialLoad = false) {
    const county = this.countyTarget.value;
    const url = `/county_delivery_groups/${county}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Assuming 'region' is a select dropdown
      const regionSelect = this.regionTarget;
      regionSelect.innerHTML = ""; // Clear previous options
      const locationSelect = this.locationTarget;
      locationSelect.innerHTML = ""; // Clear previous options

      if (data.length > 1) {
        // Add a blank option as the first choice
        const blankOption = document.createElement("option");
        blankOption.value = ""; // This value can be blank or any placeholder you'd like
        blankOption.textContent = "Select a region"; // Placeholder text for blank option
        regionSelect.appendChild(blankOption);
      }

      data.forEach((region) => {
        const option = document.createElement("option");
        option.value = region.name;
        option.textContent = region.name;
        regionSelect.appendChild(option);
      });

      // Set the selected value if it's the initial load (edit form)
      if (isInitialLoad && regionSelect.dataset.selected) {
        regionSelect.value = regionSelect.dataset.selected;
        // Trigger the locations fetch for the selected region
        this.fetchLocations(true);
      } else {
        // Trigger change event to fetch locations for the newly loaded regions
        regionSelect.dispatchEvent(new Event("change"));
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  }

  async fetchLocations(isInitialLoad = false) {
    const county = this.countyTarget.value;
    const region = this.regionTarget.value;
    if (region) {
      const url = `/group_delivery_zones/${region}/${county}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        // Assuming 'location' is a select dropdown
        const locationSelect = this.locationTarget;
        locationSelect.innerHTML = ""; // Clear previous options

        if (data.length > 1) {
          // Add a blank option as the first choice
          const blankOption = document.createElement("option");
          blankOption.value = ""; // This value can be blank or any placeholder you'd like
          blankOption.textContent = "Select a location"; // Placeholder text for blank option
          locationSelect.appendChild(blankOption);
        }


        data.forEach((location) => {
          const option = document.createElement("option");
          option.value = location.name;
          option.textContent = location.name + (location.label ? ` - ${location.label}` : '');
          locationSelect.appendChild(option);
        });

        // Set the selected location if it's the initial load
        if (isInitialLoad && locationSelect.dataset.selected) {
          locationSelect.value = locationSelect.dataset.selected;
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    } else {
      const locationSelect = this.locationTarget;
      locationSelect.innerHTML = ""; // Clear previous options

      const blankOption = document.createElement("option");
      blankOption.value = ""; // This value can be blank or any placeholder you'd like
      blankOption.textContent = "Select a region first then a location"; // Placeholder text for blank option
      locationSelect.appendChild(blankOption);
    }
  }
}