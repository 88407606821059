// app/javascript/controllers/unsaved_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.originalValues = this.inputTargets.map(input => input.value)
    this.inputTargets.forEach((input) => {
      input.addEventListener('input', this.checkChange.bind(this))
    })
  }

  checkChange() {
    const allValuesUnchanged = this.inputTargets.every((input, index) => {
      return input.value === this.originalValues[index]
    })

    this.updateBannerVisibility(allValuesUnchanged)
  }

  updateBannerVisibility(allValuesUnchanged) {
    const banner = document.getElementById("unsaved-changes-banner")

    if (allValuesUnchanged) {
      banner.style.visibility = 'hidden'
    } else {
      banner.style.visibility = 'visible'
    }
  }

  disconnect() {
    this.inputTargets.forEach((input) => {
      input.removeEventListener('input', this.checkChange.bind(this))
    })
  }
}
