import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.lazyLoadImages();
  }

  lazyLoadImages() {
    const lazyImages = this.element.querySelectorAll("img");

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          img.classList.remove("lazy");
          observer.unobserve(img);
        }
      });
    });
    
    lazyImages.forEach(image => {
      observer.observe(image);
    });
  }
}