import { Controller } from "stimulus";
export default class extends Controller {
    static targets = ["checkbox", "giftOptionsForm"];

    toggleCheckbox() {
        // Get the checkbox element
        const checkbox = this.checkboxTarget
        // Toggle the checkbox's checked state
        checkbox.checked = !checkbox.checked;

        this.toggleForm();
    }

    toggleForm() {
        const checkbox = this.checkboxTarget
        if (checkbox.checked) {
            this.giftOptionsFormTarget.classList.remove("d-none");
        } else {
            this.giftOptionsFormTarget.classList.add("d-none");
        }
    }

    connect() {
        // Add an event listener for direct checkbox clicks
        this.checkboxTarget.addEventListener('click', () => {
            this.toggleForm();
        });
    }
}