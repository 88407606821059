// app/javascript/controllers/clickable_row_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row"]

  connect() {
    this.rowTargets.forEach((row) => {
      row.addEventListener("click", () => {
        const url = row.getAttribute("data-url");
        if (url) {
          window.location.href = url;
        }
      });
    });
  }
}