// app/javascript/controllers/confirm_popup_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { message: String }

  confirm(event) {
    event.preventDefault(); // Prevent the default link action

    const message = this.messageValue || "Are you sure you want to proceed?";

    swal({
      title: "Please confirm",
      text: message,
      icon: "warning",
      buttons: ["Cancel", "Yes, proceed"],
      dangerMode: true
    }).then((willProceed) => {
      if (willProceed) {
        // Redirect if the user confirms
        window.location.href = this.element.href;
      }
    });
  }
}