import { Controller} from "stimulus"
export default class extends Controller {
    static targets = ["price", "costPrice", "profit", "margin", "percentageDiscount", "salePrice"]

    calculateProfit() {
        let price = this.priceTarget.value
        let costPrice = this.costPriceTarget.value

        let profit = price - costPrice
        let margin = (profit / price) * 100

        if (price && costPrice) {
            this.profitTarget.value = profit + ' KES'
            this.marginTarget.value = parseFloat(margin).toFixed(2) + '%'
        } else {
            this.profitTarget.value = '- - KES'
            this.marginTarget.value =  '- - %'          
        }
    }

    handleInputField(target) {
        // Determine which field is being edited and call calculateDiscount accordingly
        if (target == this.percentageDiscountTarget) {
            this.calculateDiscount("percentageDiscount");
        } else if (target == this.salePriceTarget) {
            this.calculateDiscount("salePrice");
        } else if (target == this.priceTarget) {
            this.calculateDiscount("percentageDiscount");
        }
    }

    calculateDiscount(updatedField) {
        const percentageDiscount = parseFloat(this.percentageDiscountTarget.value);
        const salePrice = parseFloat(this.salePriceTarget.value);
        const price = parseFloat(this.priceTarget.value);

        if (updatedField === "percentageDiscount" && !isNaN(percentageDiscount)) {
            let percentageLeft = 100 - percentageDiscount;
            this.salePriceTarget.value = (percentageLeft / 100) * price;
        } else if (updatedField === "salePrice" && !isNaN(salePrice)) {
            let discount = price - salePrice;
            this.percentageDiscountTarget.value = (discount / price) * 100;
        } else {
            this.salePriceTarget.value = "";
            this.percentageDiscountTarget.value = "";
        }
    }

    connect() {
        this.observeField(this.percentageDiscountTarget);
        this.observeField(this.salePriceTarget);
        this.observeField(this.priceTarget);
    }

    observeField(target) {
        target.addEventListener("input", () => {
            this.handleInputField(target);
        });
    }
}