// app/javascript/controllers/payment_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["transactionCode", "changeDue", "changeDueInput", "amountPaid", "submitButton", "paymentType"]

  connect() {
    // Set initial state (you can also use these values to reset the form if needed)
    this.hideTransactionCode()
    this.hideChangeDue()
    this.submitButtonTarget.classList.add("d-none")
  }

  selectPaymentType(event) {
    const selectedType = event.currentTarget.dataset.posPaymentType

    // Update hidden payment type input
    this.paymentTypeTarget.value = selectedType

    // Add active class to the selected card, remove from others
    this.clearActiveClasses()
    event.currentTarget.classList.add('active')

    // Show/Hide form fields based on payment type
    if (selectedType === 'Cash') {
      this.hideTransactionCode()
      this.showChangeDue()
    } else if (selectedType === 'Card') {
      this.hideTransactionCode()
      this.hideChangeDue()
    } else if (selectedType === 'Mpesa') {
      this.showTransactionCode()
      this.hideChangeDue()
    }

    // Show the submit button once a payment method is selected
    this.submitButtonTarget.classList.remove('d-none')
  }

  showTransactionCode() {
    this.transactionCodeTarget.parentElement.classList.remove('d-none')
  }

  hideTransactionCode() {
    this.transactionCodeTarget.parentElement.classList.add('d-none')
  }

  showChangeDue() {
    this.changeDueTarget.classList.remove('d-none')
    this.calculateChangeDue()
  }

  hideChangeDue() {
    this.changeDueTarget.classList.add('d-none')
    this.changeDueTarget.value = ''
  }

  calculateChangeDue() {
    const totalPrice = parseFloat(this.changeDueTarget.dataset.posPaymentTotalPrice)
    const amountPaid = parseFloat(this.amountPaidTarget.value) || 0
    const changeDue = amountPaid - totalPrice
    // If change is negative or zero, it should show zero
    this.changeDueInputTarget.value = changeDue > 0 ? changeDue.toFixed(2) : 0
  }

  clearActiveClasses() {
    document.querySelectorAll('.custom-card').forEach(card => {
      card.classList.remove('active')
    })
  }
}