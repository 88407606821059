import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [ "codeInput" ]

  generateRandomCode() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    for (let i = 0; i < 8; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    this.codeInputTarget.value = code;
  }
}