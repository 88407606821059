import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "bigImage"
  ];

  static values = {
    mobileImage: Boolean
  }

  showBigImage() {
    if (!this.mobileImageValue) {
      this.bigImageTarget.classList.remove("big-device")
    } else {
        this.bigImageTarget.classList.add("big-device")
      }
  }
  connect() { 
    this.showBigImage()
  }
}
