import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["slider"];

    connect() {
      this.replaceSliders();
    }
  
    replaceSliders() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 480;
  
      this.sliderTargets.forEach((sliderElement) => {
        const slider = sliderElement.dataset;

  
        if (isMobile && slider.mobileImageAttached === "true") {
          // Replace with mobile image URL
          const mobileImageWithBlur = slider.mobileImageBlur;
          sliderElement.style.backgroundImage = `url(${mobileImageWithBlur})`;
          sliderElement.dataset.original = slider.mobileImage;
        }
      });
    }
}
