import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "form", "template", "linkField"];

  add(event) {
    event.preventDefault();
    const formData = new FormData(this.formTarget);
    const name = formData.get("menu_item[name]");
    const menuItemType = formData.get("menu_item[menu_item_type]");
    const link = formData.get("menu_item[link]");
    const parentId = formData.get("menu_item[parent_id]");

    if (!name || !menuItemType || !link) {
      alert("All fields are required.");
      return;
    }

    const templateContent = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime())
      .replace(/TEMPLATE_NAME/g, name)
      .replace(/TEMPLATE_TYPE/g, menuItemType)
      .replace(/TEMPLATE_LINK/g, link)
      .replace(/TEMPLATE_PARENT_ID/g, parentId);

      if (parentId) {
        this.containerTarget.querySelector(`.nested-menu-items-${parentId}`).insertAdjacentHTML('beforeend', templateContent);
      } else {
        this.containerTarget.insertAdjacentHTML('beforeend', templateContent);
      }
    this.clearForm();
  }

  remove(event) {
    event.preventDefault();
    const field = event.target.closest(".nested-fields");

    if (field.dataset.newRecord === "true") {
      field.remove();
    } else {
      field.style.display = "none";
      field.querySelector("input[name*='_destroy']").value = "true";
    }
  }

  toggleLinkField() {
    const menuItemType = this.formTarget.querySelector("#menu_item_menu_item_type").value;
    if (menuItemType === "Link") {
      this.linkFieldTarget.style.display = "block";
      this.formTarget.querySelector("#menu_item_link").value = "";
    } else {
      this.linkFieldTarget.style.display = "none";
      this.formTarget.querySelector("#menu_item_link").value = "#";
    }
  }

  clearForm() {
    this.formTarget.reset();
    this.toggleLinkField()
  }

  setParentId(event) {
    const parentId = event.currentTarget.dataset.nestedFieldsParentId;
    this.formTarget.querySelector("#parent_id").value = parentId || "";
  }

  resetParentId() {
    this.formTarget.querySelector("#parent_id").value = "";
  }

  connect() {
    this.toggleLinkField()
  }
}
