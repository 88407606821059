import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["width", "height", "calculatedArea", "calculatedPrice", "calculatedFormPrice", "disclaimer"];
    static values = {
        original: Number
    }

    calculateArea() {
        const width = parseFloat(this.widthTarget.value);
        const height = parseFloat(this.heightTarget.value);

        let materialSize;
        let minValue
        if (height <= 2.5) {
            materialSize = 2.5;
            minValue = 0
        } else if (height <= 2.8) {
            materialSize = 2.8;
            minValue = 2.6
        } else if (height <= 3,2) {
            materialSize = 3.2;
            minValue = 2.9
        } else {
            // Handle cases where height exceeds the specified ranges
            // For simplicity, you can set a default material size or handle the error as needed
            materialSize = 0; // Set default value to 0
            // You can add further error handling logic here
        }

        // Check if both width and height are present and valid
        if (!isNaN(width) && !isNaN(height)) {
            const area = width * materialSize;
            this.calculatedAreaTarget.textContent = area.toFixed(2) + ' sqm'; // Display area with 2 decimal places
            let materialSizeMax = materialSize - 0.01
            this.disclaimerTarget.textContent = `${minValue}m to ${ materialSizeMax.toFixed(2) }m material used ${ materialSize }M`
            this.calculatePrice();
        } else {
            // If either width or height is missing, clear the calculated area and price
            this.calculatedAreaTarget.textContent = '';
            this.calculatedPriceTarget.textContent = '';
            this.calculatedFormPriceTarget.value = '';
            this.disclaimerTarget.textContent = '';
        }
    }

    calculatePrice() {
        const area = parseFloat(this.calculatedAreaTarget.textContent);
        const listingPrice = this.originalValue
        const totalPrice = area * listingPrice;
        this.calculatedPriceTarget.textContent = this.formatNumberWithCommas(totalPrice.toFixed(0)) + ' KES'; // Display total price with 2 decimal places
        this.calculatedFormPriceTarget.value = totalPrice.toFixed(0); // Update hidden form field with total price
    }

    formatNumberWithCommas(number) {
        // Convert number to string and use regex to insert commas every three digits
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}