import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "element"]

  connect() {
    this.addFlags();
    this.selectTarget.addEventListener('change', () => {
      this.elementTarget.requestSubmit();
    })
  }

  addFlags() {
    this.selectTarget.querySelectorAll('option').forEach(option => {
      const flag = option.dataset.countryFlag;
      if (flag) {
        option.textContent = `${flag} ${option.textContent}`;
      }
    });
  }
}
