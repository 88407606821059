// app/javascript/controllers/tag_input_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "addedTags", "hiddenInput"];

  connect() {
    console.log("Tag input controller connected");
    this.loadInitialTags();
  }

  loadInitialTags() {
    const initialTags = this.hiddenInputTarget.value.split(",");
    initialTags.forEach(tag => {
      if (tag.trim()) {
        this.addTagToDisplay(tag);
      }
    });
  }

  addTag(event) {
    if (event.key === "Enter" && this.inputTarget.value.trim() !== "") {
      event.preventDefault(); // Prevent form submission on Enter
      const newTag = this.inputTarget.value.trim();

      if (!this.isAlreadyAdded(newTag)) {
        this.addTagToDisplay(newTag);
        this.updateHiddenInput();
      }

      this.inputTarget.value = ""; // Clear the input field
    }
  }

  addTagToDisplay(tag) {
    const span = document.createElement("span");
    span.classList.add("badge", "badge-secondary", "mx-1", "my-1");
    span.setAttribute("data-tag", tag);
    span.innerHTML = `${tag} <span class="remove-tag" data-action="click->tag-input#removeTag" style="cursor: pointer;">&times;</span>`;

    this.addedTagsTarget.appendChild(span);
  }

  removeTag(event) {
    const tagToRemove = event.target.parentElement.getAttribute("data-tag");

    // Remove the span from the display
    const span = this.addedTagsTarget.querySelector(`[data-tag="${tagToRemove}"]`);
    if (span) span.remove();

    this.updateHiddenInput();
  }

  isAlreadyAdded(tag) {
    return this.addedTagsTarget.querySelector(`[data-tag="${tag}"]`);
  }

  updateHiddenInput() {
    const tags = Array.from(this.addedTagsTarget.children).map(span => span.getAttribute("data-tag"));
    this.hiddenInputTarget.value = tags.join(",");
  }
}