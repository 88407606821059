// app/javascript/controllers/slick2_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slick", "placeholder"];
  static values = { rowCount: Number }

  connect() {
    this.initializeSlick();
    this.placeholderTarget.style.display = "none";
    this.slickTarget.style.display = "block";
  }
  
  initializeSlick() {
    const slides = this.slickTarget.classList[0] // For some reason, using stimulus values was not working 4 me
    const tabletCount = (slides == 6) ? 4 : 3
    const tabletSmCount = (slides == 6) ? 3 : 2
    const rowCount = this.rowCountValue
    function myFunc(rowCount, slides) {
      if (rowCount < 2) {
        return {
          slidesToShow: slides,
        };
      } else {
        return {
          slidesPerRow: slides,
        };
      }
    }
    const settings = {
      ...myFunc(rowCount, slides),
      slidesToScroll: 2,
      infinite: true,
      autoplay: false,
      arrows: true,
      rows: rowCount,
      swipeToSlide: true,
      appendArrows: this.element,
      lazyLoad: 'ondemand',
      prevArrow: '<button class="arrow-slick2 prev-slick2 custom slick-arrow" name="button"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="arrow-slick2 next-slick2 custom slick-arrow" name="button"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            ...myFunc(rowCount, slides),
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            ...myFunc(rowCount, tabletCount),
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            ...myFunc(rowCount, tabletSmCount),
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            ...myFunc(rowCount, 2),
            slidesToScroll: 1
          }
        }
      ]
    };

    // Use JavaScript to initialize Slick slider
    if (this.slickTarget) {
        $(this.slickTarget).slick(settings);
    }
  }
}
