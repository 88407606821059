$(document).ready(function() {
  function setupAutocomplete(selector, options) {
    console.log("Initializing autocomplete for", selector);
    $(selector)
      .autocomplete(options)
      .data("ui-autocomplete")._renderItem = function(ul, item) {
        return $("<li></li>")
          .data("item.autocomplete", item)
          .append("<a>" + item.first_name + " " + item.last_name + "<br>" + item.phone_number + "</a>")
          .appendTo(ul);
      };
  }

  const autocompleteOptionsOrderUser = {
    minLength: 1,
    source: "/users",
    delay: 100,
    focus: function(event, ui) {
      $("#order_user").val(ui.item.first_name + " " + ui.item.last_name);
      return false;
    },
    select: function(event, ui) {
      $(".order-details-steps").show(200); // To show the order form
      $('html,body').animate({
        scrollTop: $(".order-details-steps").offset().top
      }, 'slow'); // To scroll to the bottom of the form

      // Set values based on the selected user
      $("#order_user").val(ui.item.first_name + " " + ui.item.last_name);
      $("#order_user_id").val(ui.item.id);
      $("#order_fullname").val(ui.item.first_name + " " + ui.item.last_name);
      $("#order_phone_number").val(ui.item.phone_number);
      $("#order_email").val(ui.item.email);

      return false;
    }
  };

  const autocompleteOptionsNewUser = {
    minLength: 1,
    source: "/users",
    delay: 100,
    focus: function(event, ui) {
      $("#new_user").val(ui.item.first_name + " " + ui.item.last_name);
      return false;
    },
    select: function(event, ui) {
      // Set values based on the selected user
      $("#new_user").val(ui.item.first_name + " " + ui.item.last_name);
      $("#user_id").val(ui.item.id);
      $("#fullname").val(ui.item.first_name + " " + ui.item.last_name);
      $("#phone_number").val(ui.item.phone_number);
      $("#email").val(ui.item.email);

      return false;
    }
  };


  if ($("#order_user").length > 0) {
    setupAutocomplete("#order_user", autocompleteOptionsOrderUser);
  } else if ($("#new_user").length > 0) {
    setupAutocomplete("#new_user", autocompleteOptionsNewUser);
  }
});
