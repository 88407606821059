import { Controller } from "stimulus";
export default class extends Controller {
    static targets = ["placeholderText", "subcategoriesDiv", "subcategoriesForm", "subcategoryBtn"]
    static values = {
      subId: Number
    }

    async updateSubcategories() {
        const selectedCategoryId = this.selectedCategoryId();
        if (selectedCategoryId) {
          this.placeholderTextTarget.innerHTML = 'Loading...';
          this.subcategoriesFormTarget.action = `/categories/${selectedCategoryId}/sub_categories`
          this.subcategoryBtnTarget.style.display = "block";
          this.subcategoriesDivTarget.innerHTML = await this.fetchSubcategories(selectedCategoryId);
          this.placeholderTextTarget.style.display = "none";
        } else {
            this.placeholderTextTarget.innerHTML = "Select a category first";
        }
    }

    selectedCategoryId() {
        const selectedRadioButton = this.element.querySelector(
          "input[type='radio']:checked"
        );
    
        return selectedRadioButton ? selectedRadioButton.value : null;
      }

    async fetchSubcategories(categoryId) {
        const subId = this.subIdValue
        const response = await fetch(`/categories/${categoryId}/subcategories?selected_sub_value=${subId}`);
        return response.text();
    }

    connect() {
        console.log("We are good to go!");
        this.updateSubcategories()
      }
}