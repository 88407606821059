// app/javascript/controllers/submit_on_click_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overlay"]

  submitForm(event) {
    event.preventDefault();
    this.overlayTarget.classList.add('active');

    this.element.querySelector('form').requestSubmit();

    setTimeout(() => {
      this.overlayTarget.classList.remove('active');
    }, 3000);
  }
}