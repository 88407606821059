import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "message"];

  initialize() {
    this.index = 0;
    this.autoplayInterval = null;
    this.updateVisibility();
    this.startAutoplay();
  }

  next() {
    this.index = (this.index + 1) % this.messageTargets.length;
    this.updateSlider();
  }

  prev() {
    this.index = (this.index - 1 + this.messageTargets.length) % this.messageTargets.length;
    this.updateSlider();
  }

  updateSlider() {
    const offset = -this.index * 100;
    this.wrapperTarget.style.transform = `translateX(${offset}%)`;
  }

  updateVisibility() {
    if (this.messageTargets.length > 1) {
      this.element.querySelector('.left').style.display = 'block';
      this.element.querySelector('.right').style.display = 'block';
    }
  }

  startAutoplay() {
    if (this.messageTargets.length > 1) {
      this.autoplayInterval = setInterval(() => {
        this.next();
      }, 5000); // Change slide every 5 seconds
    }
  }

  stopAutoplay() {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
    }
  }

  // Optional: Stop autoplay when the user interacts with the arrows
  disconnect() {
    this.stopAutoplay();
  }
}