import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "selectedItems", "wrapper", "hiddenField"];
  static values = {
    selectedValues: Array
  }

  connect() {
    this.selectedCollections = new Set();
    this.loadSelectedItems();
  }

  loadSelectedItems() {
    // Preload selected collections when editing a listing
    this.selectedValuesValue.forEach(value => {
      const selectedCollection = this.selectTarget.querySelector(`[data-id-value="${value}"]`);
      
      if (selectedCollection) {
        const name = selectedCollection.getAttribute('data-name-value'); // Get the collection's name
        this.addItemToDisplay({ idValue: value, nameValue: name });
      }
    });
  }

  addItem(event) {
    const selectedOption = event.target.dataset;

    if (selectedOption && !this.isAlreadySelected(selectedOption.idValue)) {
      this.addItemToDisplay(selectedOption);
    }
  }

  addItemToDisplay(option) {
    // Create the span element for the selected collection
    const span = document.createElement("span");
    span.classList.add("badge", "badge-secondary", "mx-1", "my-1");
    span.setAttribute("data-id", option.idValue);
    span.innerHTML = `${option.nameValue} <span class="remove-item" data-action="click->multi-select#removeItem" data-id="${option.idValue}" style="cursor: pointer;">&times;</span>`;

    // Append the span to the selected items div
    this.selectedItemsTarget.appendChild(span);
    this.selectedCollections.add(option.idValue);
    this.updateHiddenFields();
  }

  isAlreadySelected(value) {
    // Check if the item is already displayed
    return this.selectedItemsTarget.querySelector(`[data-id="${value}"]`);
  }

  removeItem(event) {
    const idToRemove = Number(event.target.dataset.id);

    // Remove the span from the display
    const span = this.selectedItemsTarget.querySelector(`[data-id="${idToRemove}"]`);
    if (span) span.remove();

    this.selectedCollections.delete(idToRemove);
    this.updateHiddenFields();
  }

  updateHiddenFields() {
    // Clear the previous hidden fields
    this.hiddenFieldTarget.innerHTML = "";

    // For each selected collection, create a hidden input field with the same name
    this.selectedCollections.forEach(id => {
      const hiddenInput = document.createElement("input");
      hiddenInput.type = "hidden";
      hiddenInput.name = "listing[collection_ids][]"; // This ensures Rails treats it as an array
      hiddenInput.value = id;
      this.hiddenFieldTarget.appendChild(hiddenInput);
    });
  }
}