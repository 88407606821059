// custom_dropdown_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "link"];

  toggleDropdown() {
    this.menuTarget.classList.toggle("open");
  }

  truncateText(text) {
    if (text.length > 30) {
      return text.substring(0, 42) + "...";
    } else {
      return text;
    }
  }

  copyLink() {
    const linkContent = window.location.href
    // Copy the link content to clipboard
    navigator.clipboard.writeText(linkContent)
      .then(() => {
        // Update the text content to indicate successful copy
        this.linkTarget.textContent = "Link copied to clipboard";
        
        // Revert back to the original text content after 3 seconds
        setTimeout(() => {
          this.linkTarget.textContent = this.truncateText(linkContent);
        }, 3000); // 3000 milliseconds = 3 seconds
      })
      .catch((error) => {
        console.error("Unable to copy link: ", error);
      });
  }

  connect() {
    const linkContent = window.location.href
    this.linkTarget.textContent = this.truncateText(linkContent);
  }
}
