// app/javascript/controllers/variant_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hiddenField"]

  connect() {
    this.element.querySelectorAll(".variant-group input[type='checkbox']").forEach(checkbox => {
      checkbox.addEventListener("click", this.updateHiddenField.bind(this))
    })
  }

  updateHiddenField(event) {
    const checkboxes = this.element.querySelectorAll(".variant-group input[type='checkbox']")
    const checkedCheckboxes = Array.from(checkboxes).filter(checkbox => checkbox.checked)

    if (checkedCheckboxes.length === 0) {
      this.hiddenFieldTarget.value = ""
      return
    }

    const names = checkedCheckboxes.map(checkbox => checkbox.nextElementSibling.textContent.trim())
    const amounts = names.map(name => extractAmount(name))
    const minAmount = Math.min(...amounts.filter(amount => amount !== null))

    this.hiddenFieldTarget.value = minAmount
  }
}

function extractAmount(str) {
  const match = str.match(/[\d,]+/)
  return match ? parseInt(match[0].replace(/,/g, '')) : null
}
