import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"];
  
  connect() {
    this.endTime = new Date(this.element.dataset.endTime); // Use the data-end-time attribute to get the countdown end time
    this.updateCountdown();
    this.timer = setInterval(() => this.updateCountdown(), 1000); // Update every second
  }

  disconnect() {
    // Clear the interval when the controller is disconnected
    clearInterval(this.timer);
  }

  updateCountdown() {
    const now = new Date();
    const timeRemaining = this.endTime - now;

    if (timeRemaining <= 0) {
      // Time has expired, set all to zero
      this.daysTarget.textContent = "0";
      this.hoursTarget.textContent = "0";
      this.minutesTarget.textContent = "0";
      this.secondsTarget.textContent = "0";
      clearInterval(this.timer);
      return;
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    // Update the elements in the DOM
    this.daysTarget.textContent = days;
    this.hoursTarget.textContent = hours;
    this.minutesTarget.textContent = minutes;
    this.secondsTarget.textContent = seconds;
  }
}