// app/javascript/controllers/add_to_cart_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  addToCart() {
    const button = this.buttonTarget;
    button.value = "Adding...";
    
    // Perform AJAX request to add item to cart
    // You can use fetch or any AJAX library you prefer
    
    // After successful addition to cart, you can update the button text back to "Add to cart"
    // For demonstration purposes, I'm simulating a delay using setTimeout
    setTimeout(() => {
      button.value = "Add to cart";
    }, 2000); // Adjust the delay as needed
  }
}
