import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "deliveryInfo",
    "toggleButton",
    "deliveryOption",
    "addressOption",
    "pickupOption",
    "deliveryType",
    "completedDelivery"
  ];
  toggleDelivery(event) {
    const deliveryType = event.currentTarget;
    const option = deliveryType.dataset.option;

    this.deliveryOptionTargets.forEach(element => {
      const isVisible = element.dataset.option === option;
      if (isVisible) {
        element.classList.remove("hidden");
        element.classList.add("visible");
        this.deliveryTypeTarget.value = element.dataset.type;
      } else {
        element.classList.remove("visible");
        element.classList.add("hidden");
      }
    });

    if (!deliveryType.classList.contains("active")) {
      this.clearActiveButtons();
      deliveryType.classList.add("active", "button-transition");
    }
  }

  selectDefaults() {
    const addressCheckboxes = this.addressOptionTargets;
    const stationCheckboxes = this.pickupOptionTargets;

    if (
      addressCheckboxes.length > 0 &&
      !addressCheckboxes.some(checkbox => checkbox.checked)
    ) {
      addressCheckboxes[0].checked = true;
    }

    if (
      stationCheckboxes.length > 0 &&
      !stationCheckboxes.some(checkbox => checkbox.checked)
    ) {
      stationCheckboxes[0].checked = true;
    }
  }

  clearActiveButtons() {
    this.toggleButtonTargets.forEach(button =>
      button.classList.remove("active")
    );
  }

  setCompletedDeliveryType() {
    const completedDeliveryType = this.completedDeliveryTarget.dataset
      .savedDelivery;
    if (completedDeliveryType !== null && completedDeliveryType === "Pick up") {
      if (this.toggleButtonTargets.length > 0) {
        this.toggleButtonTargets[0].classList.remove("active");
        this.toggleButtonTargets[1].classList.add("active");
        this.deliveryTypeTarget.value = "Pick up";
      }
      if (this.deliveryOptionTargets.length > 0) {
        this.deliveryOptionTargets[1].classList.remove("visible");
        this.deliveryOptionTargets[1].classList.add("hidden");
        this.deliveryOptionTargets[0].classList.remove("hidden");
        this.deliveryOptionTargets[0].classList.add("visible");
      }
    }
  }

  connect() {
    console.log("We are good to go!");
    this.selectDefaults();
    this.setCompletedDeliveryType();
  }
}
