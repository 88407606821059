// custom_modal_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["customModal", "dynamicContent"];

  initialize() {
    // Add event listener to close sidebar when clicking outside of sidebar-content
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  async open() {
    this.customModalTarget.classList.add("open");
    const listingId = this.customModalTarget.getAttribute('data-listing-id');
    if (listingId != null) {
      await this.loadModalContent(listingId);
    }
  }

  close() {
    this.customModalTarget.classList.remove("open");
    const listingId = this.customModalTarget.getAttribute('data-listing-id');
    if (listingId != null) {
      this.dynamicContentTarget.innerHTML = ""; // Clear the modal content
    }
  }

  handleClickOutside(event) {
    if (event.target.classList.contains('open')) {
      this.close();
    }
  }

  async loadModalContent(listingId) {
    this.dynamicContentTarget.innerHTML = '<div class="text-center mt-5">Loading...</div>'
    try {
      const response = await fetch(`/listings/${listingId}/modal_content`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.text();

      this.dynamicContentTarget.innerHTML = data;
      this.reinitializelazyLoad();
    } catch (error) {
      console.error("Failed to load modal content:", error);
    }
  }

  reinitializelazyLoad() {
    $("img").lazyload({
      failure_limit: Math.max(jQuery("img").length - 1, 0)
    });
  }
}
