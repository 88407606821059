// app/javascript/controllers/counter_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  increment() {
    this.updateValue(1)
    this.triggerChangeDetection()
  }

  decrement() {
    this.updateValue(-1)
    this.triggerChangeDetection()
  }

  updateValue(amount) {
    let value = parseInt(this.inputTarget.value, 10)
    if (isNaN(value)) value = 0
    this.inputTarget.value = value + amount
  }

  triggerChangeDetection() {
    const event = new Event('input', { bubbles: true })
    this.inputTarget.dispatchEvent(event)
  }
}
