import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.startCountdown();
  }

  startCountdown() {
    const targetTime = new Date(this.element.dataset.countdownTime).getTime();
    const element = this.element;
    const updateCountdown = () => {
      const now = new Date().getTime();
      const timeRemaining = targetTime - now;

      if (timeRemaining <= 0) {
        const completedText = this.element.dataset.completedText
        element.textContent = completedText;
      } else {
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        element.textContent = `${days} days ${hours} hrs ${minutes} mins ${seconds} secs`;
      }
    };

    updateCountdown();
    setInterval(updateCountdown, 1000);
  }
}
